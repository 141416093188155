import React from "react"

//Styles
import footerStyles from "./Footer.module.scss"

export default function Footer() {
  return (
    <>
      <footer className={footerStyles.footer}>
        <a href="https://github.com/IkraP" target="_blank" rel="noreferrer">
          <i
            className={`ri-github-fill ${footerStyles.github}`}
            style={{ fontSize: 25 }}
            title="github-logo"
          ></i>
        </a>
        <a
          href="https://twitter.com/chemtocode"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className={`ri-twitter-fill ${footerStyles.twitter}`}
            style={{ fontSize: 25 }}
            title="twitter-logo"
          ></i>
        </a>
        <a
          href="https://linkedin.com/in/ikrapervaiz"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className={`ri-linkedin-fill ${footerStyles.linkedin}`}
            style={{ fontSize: 25 }}
            title="linkedin-logo"
          ></i>
        </a>
      </footer>
    </>
  )
}
