import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import { MDXProvider } from "@mdx-js/react"
import theme from "prism-react-renderer/themes/nightOwl"
import { motion, AnimatePresence } from "framer-motion"
// Components
import Navbar from "../components/NavBar/NavBar"
import Footer from "../components/Footer/Footer"
import useSiteMetadata from "../components/graphqlQueries/SiteMetadata"

// Styles
import layoutStyles from "../layouts/Layout.module.scss"

// framer motion page transitions

const components = {
  pre: props => {
    const className = props.children.props.className || ""
    const matches = className.match(/language-(?<lang>.*)/)

    return (
      <Highlight
        {...defaultProps}
        code={props.children.props.children.trim()}
        language={
          matches && matches.groups && matches.groups.lang
            ? matches.groups.lang
            : ""
        }
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    )
  },
  wrapper: ({ children }) => <>{children}</>,
}

const easing = [0.6, -0.05, 0.01, 0.99]
const layoutAnimation = {
  initial: { y: 60, opacity: 0, transition: { duration: 0.6, ease: easing } },
  animate: { y: 0, opacity: 1, transition: { duration: 0.3, ease: easing } },
  exit: { opacity: 0 },
}

export default function Layout({ children, location }) {
  const { siteUrl } = useSiteMetadata()
  return (
    <>
      <MDXProvider components={components}>
        <div className={layoutStyles.container}>
          <AnimatePresence exitBeforeEnter intial={false}>
            <motion.main
              key={location.pathname}
              variants={layoutAnimation}
              initial="initial"
              animate="animate"
              exit="exit"
              className={layoutStyles.wrapper}
            >
              <Navbar href={`${siteUrl}${location.pathname}`} />
              {children}
              <Footer />
            </motion.main>
          </AnimatePresence>
        </div>
      </MDXProvider>
    </>
  )
}
