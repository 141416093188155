import React from "react"
import { Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

// Styles
import navBarStyles from "./NavBar.module.scss"

//Components
import MobileNavBar from "./MobileNavBar"
import ChemistrySign from "../../assets/chemistrySign.svg"
import ErrorSign from "../../assets/errorSign.svg"
import GardenSign from "../../assets/gardenSign.svg"
import ProjectSign from "../../assets/ProjectSign.svg"
import ContactSign from "../../assets/contactSign.svg"

function signSvg(url) {
  if (url.includes("/404")) {
    return <ErrorSign />
  } else if (url.includes("/garden")) {
    return <GardenSign />
  } else if (url.includes("/projects")) {
    return <ProjectSign />
  } else if (url.includes("/contact")) {
    return <ContactSign />
  } else if (url.includes("/about") || url.includes("/")) {
    return <ChemistrySign />
  } else {
    return <GardenSign />
  }
}

export default function Navbar(props) {
  // Only display menu in mobile view

  const breakpoints = useBreakpoint()
  return (
    <>
      {breakpoints.sm ? (
        <MobileNavBar />
      ) : (
        <>
          <nav className={navBarStyles.navBar}>
            <Link
              to="/"
              className={navBarStyles.navButton}
              activeStyle={{
                color: "#2e8d65",
                borderBottom: "3px solid #2e8d65",
                fontWeight: "bold",
              }}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={navBarStyles.navButton}
              activeStyle={{
                color: "#2e8d65",
                borderBottom: "3px solid #2e8d65",
              }}
            >
              About me
            </Link>
            <Link
              to="/projects"
              className={navBarStyles.navButton}
              activeStyle={{
                color: "#2e8d65",
                borderBottom: "3px solid #2e8d65",
              }}
            >
              Projects
            </Link>
            <Link
              to="/garden"
              className={navBarStyles.navButton}
              activeStyle={{
                color: "#2e8d65",
                borderBottom: "3px solid #2e8d65",
              }}
            >
              Garden
            </Link>
            <Link
              to="/contact"
              className={navBarStyles.navButton}
              activeStyle={{
                color: "#2e8d65",
                borderBottom: "3px solid #2e8d65",
              }}
            >
              Contact
            </Link>
            {breakpoints.md ? null : (
              <div className={navBarStyles.sign}>{signSvg(props.href)}</div>
            )}
          </nav>
        </>
      )}
    </>
  )
}
