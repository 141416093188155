import React, { useState } from "react"
import { Link } from "gatsby"

// styles
import navBarStyles from "./NavBar.module.scss"

export default function MobileNavBar() {
  const [navState, setNavState] = useState(false)
  const closeNav = () => {
    setNavState(!navState)
  }
  return (
    <div onClick={closeNav} role="presentation" onKeyDown={closeNav}>
      {navState ? (
        <>
          <div className={navBarStyles.mobileNavContainer}>
            <i className="ri-close-line" style={{ fontSize: 30 }}></i>
          </div>
          <nav className={navBarStyles.mobileNavMenu}>
            <Link to="/" className={navBarStyles.mobileLinks}>
              Home
            </Link>
            <Link to="/about" className={navBarStyles.mobileLinks}>
              About
            </Link>
            <Link to="/projects" className={navBarStyles.mobileLinks}>
              Projects
            </Link>
            <Link to="/garden" className={navBarStyles.mobileLinks}>
              Garden
            </Link>
            <Link to="/contact" className={navBarStyles.mobileLinks}>
              Contact
            </Link>
          </nav>
        </>
      ) : (
        <div className={navBarStyles.mobileNavContainer}>
          <i className="ri-menu-5-line" style={{ fontSize: 30 }}></i>
        </div>
      )}
    </div>
  )
}
